import React from 'react';

export function Twitter(props: React.SVGAttributes<{}>) {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M13 0C20.1786 0 26 5.8214 26 13C26 20.1786 20.1786 26 13 26C5.8214 26 0 20.1786 0 13C0 5.8214 5.8214 0 13 0Z" fill="#1DA1F2" />
            <path
                d="M9.98466 19.6038C16.1155 19.6038 19.4721 14.5234 19.4721 10.1164C19.4721 9.97341 19.4721 9.82781 19.4617 9.68481C20.1143 9.21161 20.6785 8.62921 21.1257 7.95841C20.5173 8.22881 19.8725 8.40561 19.2121 8.48361C19.9089 8.06761 20.4289 7.41241 20.6785 6.64021C20.0233 7.02761 19.3083 7.30321 18.5621 7.44881C17.2985 6.10721 15.1873 6.04221 13.8457 7.30321C12.9799 8.11701 12.6133 9.33121 12.8811 10.4882C10.2005 10.353 7.70186 9.08681 6.00926 7.00421C5.12526 8.52781 5.57506 10.4752 7.04146 11.4554C6.51106 11.4398 5.99106 11.2968 5.52826 11.0394C5.52826 11.0524 5.52826 11.068 5.52826 11.081C5.52826 12.667 6.64626 14.0346 8.20366 14.3492C7.71226 14.4818 7.19746 14.5026 6.69826 14.4064C7.13506 15.7636 8.38566 16.6944 9.81306 16.723C8.63266 17.6512 7.17406 18.1556 5.67386 18.153C5.40866 18.153 5.14346 18.1374 4.88086 18.1036C6.39926 19.0838 8.17506 19.6038 9.98466 19.6012"
                fill="white"
            />
        </svg>
    );
}